/* PrimeNG Toast Styles */
.p-toast {
    position: fixed;
    width: min(90vw, 28rem);
    z-index: 1000;

    &.p-toast-top-right {
        top: 20px;
        right: 20px;
    }

    &.p-toast-top-left {
        top: 20px;
        left: 20px;
    }

    &.p-toast-bottom-right {
        bottom: 20px;
        right: 20px;
    }

    &.p-toast-bottom-left {
        bottom: 20px;
        left: 20px;
    }

    &.p-toast-top-center {
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.p-toast-bottom-center {
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }

    &.p-toast-center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .p-toast-message {
        margin: 0 0 0.5rem 0;
        padding: 0.5rem 1rem;
        border-radius: 6px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        cursor: pointer;
        position: relative;
        backdrop-filter: blur(8px);
        transition: transform 0.2s ease, opacity 0.2s ease;

        &:hover {
            transform: translateY(-2px);
        }

        .toast-content {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            position: relative;
            padding-right: 1.5rem;
            max-width: 100%;
            overflow: hidden;
        }

        .toast-header {
            display: flex;
            align-items: center;
            min-height: 1.5rem;
            width: 100%;
            max-width: 100%;
            overflow: hidden;

            .header-left {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                flex: 1;
                min-width: 0;
                max-width: calc(100% - 1.5rem);
                overflow: hidden;

                .pi {
                    font-size: 1.25rem;
                    flex-shrink: 0;
                }

                span {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.2;
                    letter-spacing: -0.01em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100%;
                }
            }

            .p-toast-icon-close {
                width: 1.5rem !important;
                height: 1.5rem !important;
                top: 0.5rem !important;
                right: 0.5rem !important;
                margin: 0 !important;
                padding: 0 !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                border-radius: 4px !important;
                cursor: pointer !important;
                transition: all 0.2s ease !important;
                border: none !important;
                background: transparent !important;
                opacity: 0.7 !important;
                position: absolute !important;
                z-index: 1 !important;

                .pi {
                    font-size: 0.875rem !important;
                }

                &:hover {
                    opacity: 1 !important;
                    background-color: rgba(0, 0, 0, 0.05) !important;
                }
            }
        }

        .toast-body {
            display: flex;
            align-items: flex-start;
            gap: 0.25rem;
            width: 100%;
            max-width: 100%;
            overflow: hidden;

            .icon-spacer {
                width: 1.25rem;
                flex-shrink: 0;
            }

            .body-content {
                font-size: 0.875rem;
                line-height: 1.3;
                color: inherit;
                opacity: 0.85;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: calc(1.3em * 2);
                flex: 1;
                min-width: 0;
                max-width: 100%;
            }
        }

        &.p-toast-message-success {
            background: rgba(237, 247, 237, 0.95);
            border: solid #C2E0C2;
            border-width: 0 0 0 6px;
            color: #1E4620;

            .pi {
                color: #2E7D32;
            }
        }

        &.p-toast-message-info {
            background: rgba(227, 242, 253, 0.95);
            border: solid #90CAF9;
            border-width: 0 0 0 6px;
            color: #23547B;

            .pi {
                color: #1976D2;
            }
        }

        &.p-toast-message-warn {
            background: rgba(255, 243, 224, 0.95);
            border: solid #FFB74D;
            border-width: 0 0 0 6px;
            color: #805B36;

            .pi {
                color: #F57C00;
            }
        }

        &.p-toast-message-error {
            background: rgba(253, 236, 234, 0.95);
            border: solid #EF9A9A;
            border-width: 0 0 0 6px;
            color: #C62828;

            .pi {
                color: #D32F2F;
            }
        }

        @media (max-width: 640px) {
            padding: 0.625rem 0.875rem;
            
            .toast-content {
                padding-right: 1.25rem;
            }
            
            .toast-header {
                min-height: 1.5rem;

                .header-left {
                    .pi {
                        font-size: 1.125rem;
                    }

                    span {
                        font-size: 0.9375rem;
                    }
                }
            }

            .p-toast-icon-close {
                width: 1.25rem !important;
                height: 1.25rem !important;
                top: 0.5rem !important;
                right: 0.5rem !important;
            }

            .toast-body {
                .icon-spacer {
                    width: 1.125rem;
                }
                
                .body-content {
                    font-size: 0.8125rem;
                    max-height: calc(1.4em * 2);
                }
            }
        }
    }
}