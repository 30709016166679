/* You can add global styles to this file, and also import other style files */
@import "https://fonts.googleapis.com/css2?family=Bricolage+Grotesque&display=swap";
@import "./toast.scss";
@import "./driverjs.scss";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@layer tailwind-base, primeng, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;

  .gradient {
    @apply bg-gradient-to-r from-arena-orange-600 from-15% via-white via-50% to-arena-blue-600 to-85%;
  }
}

@layer base {
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/Gilroy-Light.otf') format('otf');
  }
}

@layer primeng {}

@import url('./material.scss');
@import url(./theme.scss);
@import "material-icons/iconfont/material-icons.scss";
$material-icons-font-size: 20px !default;

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

/* styles.css */
html,
body {
  margin: 0;
  padding: 0;
  background-color: #F6F6F6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbars for all elements */
* {

  /* Hide scrollbar for WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}